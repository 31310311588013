import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Typography,
  IconButton,
  Tooltip,
  Toolbar,
  AppBar,
  Breadcrumbs,
  ButtonBase,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import WarningIcon from '@mui/icons-material/Warning';
import SaveIcon from '@mui/icons-material/Save';

import utils from 'src/utils/utils';
import CampaignConfigStep from 'src/enums/campaignConfigStep';

const useStyles = makeStyles()(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'left',
  },
  flex: { flex: 1 },
  menuItem: {
    '&[class*="disabled"]': { color: theme.palette.grey[400], opacity: 0.7 },
    color: theme.palette.text.secondary,
    outline: 'none',
    cursor: 'pointer',
    fontSize: '0.9rem',
    display: 'block',
    '&.selected': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      opacity: 0.9,
    },
  },
  breadcrumbs: { background: theme.palette.grey[50], padding: '8px 24px', flex: 1 },
}));

function EditorAppBar({
  editedCampaign,
  currentStep,
  goToEditorStep,
  recipeCfgLoaded,
  campaignActions,
  periods,
  requestModelByRMTypeId,
  recipesById,
  selectedType,
  visibleBtns,
  saveCampaign,
  campaignValidity,
  smartjourney,
}) {
  const { classes } = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar style={{ padding: '0px 24px' }}>
        <div className={classes.flex}>
          <Typography variant="h5" color="inherit">
            {editedCampaign.name || utils.getLang('smartmessaging.campaignEditor.emptyNameTitle')}
            {!!editedCampaign.id && ` (${editedCampaign.id})`}
          </Typography>
          {(selectedType || editedCampaign.recipeId) && (
            <Typography variant="body2" color="inherit">
              {smartjourney.name}
              {' - '}
              {(editedCampaign.recipeId &&
                utils.getLang(
                  `smartmessaging.requestmodel.label.${
                    requestModelByRMTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId]
                      .name
                  }`
                )) ||
                utils.getLang(`smartmessaging.requestmodel.label.${selectedType.name}`)}
            </Typography>
          )}
        </div>
        {visibleBtns && editedCampaign.isWritable && (
          <Fragment>
            <div
              style={{
                boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
                padding: '0 8px',
              }}
            >
              <Tooltip title={utils.getLang('smartmessaging.button.campaignEditor.save')}>
                <IconButton
                  disabled={!campaignValidity.isValid}
                  style={{ margin: '2px' }}
                  onClick={e => {
                    e.preventDefault();
                    saveCampaign(true);
                  }}
                  size="large"
                >
                  <SaveIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </div>
          </Fragment>
        )}
      </Toolbar>
      <Breadcrumbs
        className={classes.breadcrumbs}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <ButtonBase
          className={`${classes.menuItem} ${
            currentStep === CampaignConfigStep.INFORMATIONS ? 'selected' : ''
          }`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CampaignConfigStep.INFORMATIONS);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.infos')}
          </Typography>
        </ButtonBase>
        <ButtonBase
          disabled={!recipeCfgLoaded}
          className={`${classes.menuItem} ${
            currentStep === CampaignConfigStep.RECIPE_CONFIG ? 'selected' : ''
          }`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CampaignConfigStep.RECIPE_CONFIG);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.targetedCustomers')}
          </Typography>
        </ButtonBase>
        <ButtonBase
          disabled={!(campaignActions.length || CampaignConfigStep.CONTENT_SETUP === currentStep)}
          className={`${classes.menuItem} ${
            currentStep === CampaignConfigStep.CONTENT_SETUP ? 'selected' : ''
          }`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CampaignConfigStep.CONTENT_SETUP);
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!!editedCampaign.duplicates.length && (
              <Tooltip title={utils.getLang('smartmessaging.campaignAction.duplicateWarning')}>
                <WarningIcon color="error" />
              </Tooltip>
            )}
            <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
              {utils.getLang('smartmessaging.campaignEditor.menuItem.content')}
            </Typography>{' '}
          </div>
        </ButtonBase>
        <ButtonBase
          disabled={
            !(CampaignConfigStep.SCHEDULING === currentStep || (!!periods && !!periods.length))
          }
          className={`${classes.menuItem} ${
            currentStep === CampaignConfigStep.SCHEDULING ? 'selected' : ''
          }`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CampaignConfigStep.SCHEDULING);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.schedule')}
          </Typography>
        </ButtonBase>
      </Breadcrumbs>
    </AppBar>
  );
}

EditorAppBar.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  recipeCfgLoaded: PropTypes.bool.isRequired,
  campaignActions: PropTypes.array.isRequired,
  periods: PropTypes.array.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  visibleBtns: PropTypes.bool.isRequired,
  saveCampaign: PropTypes.func.isRequired,
  campaignValidity: PropTypes.object.isRequired,
  smartjourney: PropTypes.object.isRequired,
};

EditorAppBar.defaultProps = {
  selectedType: null,
};

export default EditorAppBar;
