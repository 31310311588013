import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import History from './History';
import Details from './Details';

import { loaderActions } from 'src/reducers/loader';
import getApiCaller from 'src/utils/apiClientCaller';
import statsApi from 'src/utils/api/stats';
import utils from 'src/utils/utils';

function Content({ currentStep, components, ...otherProps }) {
  switch (currentStep) {
    case 0:
      return <History {...otherProps} />;
    case 1:
      return <Details {...otherProps} UsedFilter={components && components.UsedFilter} />;
    default:
      return null;
  }
}

Content.propTypes = {
  components: PropTypes.any,
  currentStep: PropTypes.number.isRequired,
};

Content.defaultProps = { components: null };

function HistoryPageHandler({ setLoader, doGetApiCaller, showAlert, lang, ...props }) {
  const [currentStep, goToHistoryStep] = useState(0);
  const [extraProps, setExtraProps] = useState({});
  const enhancedGoToHistoryStep = useCallback((step, newExtraProps) => {
    setExtraProps(newExtraProps || {});
    goToHistoryStep(step);
  }, []);

  const exportHistory = useCallback(
    async campaignId => {
      const callApi = doGetApiCaller();
      setLoader(true);
      const exportFileId = await callApi(statsApi.exportHistory, [campaignId, lang]).finally(() =>
        setLoader(false)
      );

      if (exportFileId && exportFileId !== -1) {
        await utils.downloadPrivateStoredFile(exportFileId, callApi);
        setLoader(false);
      } else {
        setLoader(false);
        showAlert(utils.getLang('smartmessaging.noData'));
      }
    },
    [doGetApiCaller, lang, setLoader, showAlert]
  );

  const exportExecutionResult = useCallback(
    async (execId, atId) => {
      const callApi = doGetApiCaller();
      setLoader(true);
      const exportFileId = await callApi(statsApi.exportExecutionResult, [
        execId,
        atId,
      ]).finally(() => setLoader(false));

      if (exportFileId && exportFileId !== -1) {
        await utils.downloadPrivateStoredFile(exportFileId, callApi);
        setLoader(false);
      } else {
        setLoader(false);
        showAlert(utils.getLang('smartmessaging.noData'));
      }
    },
    [doGetApiCaller, setLoader, showAlert]
  );
  return (
    <Content
      currentStep={currentStep}
      goToHistoryStep={enhancedGoToHistoryStep}
      exportExecutionResult={exportExecutionResult}
      exportHistory={exportHistory}
      {...props}
      {...extraProps}
    />
  );
}

HistoryPageHandler.propTypes = {
  setLoader: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
};

const HistoryPage = ({
  campaign,
  backToMain,
  filtersByKey,
  setLoader,
  doGetApiCaller,
  showAlert,
  receiveHistory,
  excludedProperties,
  rowSpanCols,
  sortable,
  summaryExcludedProperties,
  components,
  lang,
}) => (
  <HistoryPageHandler
    campaign={campaign}
    backToMain={backToMain}
    filtersByKey={filtersByKey}
    setLoader={setLoader}
    doGetApiCaller={doGetApiCaller}
    showAlert={showAlert}
    receiveHistory={receiveHistory}
    excludedProperties={excludedProperties}
    rowSpanCols={rowSpanCols}
    sortable={sortable}
    summaryExcludedProperties={summaryExcludedProperties}
    lang={lang}
    components={components}
  />
);

HistoryPage.propTypes = {
  backToMain: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  receiveHistory: PropTypes.func,
  excludedProperties: PropTypes.array,
  rowSpanCols: PropTypes.array,
  sortable: PropTypes.array,
  summaryExcludedProperties: PropTypes.array,
  components: PropTypes.any,
  lang: PropTypes.string.isRequired,
};

HistoryPage.defaultProps = {
  excludedProperties: [],
  receiveHistory: null,
  components: null,
  rowSpanCols: [],
  sortable: [],
  summaryExcludedProperties: [],
};

const actionCreators = {
  setLoader: visible => loaderActions.setLoader(visible),
  doGetApiCaller: () => getApiCaller,
  showAlert: msg => ({ type: 'SHOW_ALERT', value: { msg } }),
};

export default connect(
  state => ({ lang: state.app.currentLanguage.shortName }),
  actionCreators
)(HistoryPage);
