/* eslint-disable react/prop-types */
import React, { useState, useMemo, useCallback } from 'react';
import withDataResolver from '../withDataResolver';
import withApiCaller from './withApiCaller';
import apiClient from 'src/utils/apiClient';
import utils from 'src/utils/utils';
import withAlertAndLoader from './withAlertAndLoader';
import { EBeantype } from 'src/enums/beantype';
import FieldKey from 'src/enums/fieldKey';

const getDefaultValue = (key, data) => {
  switch (key) {
    case FieldKey.CIVILITY:
      return data.civilities[0].key;
    default:
      return utils.getLang(`smartmessaging.resultfield.${key}.sample`);
  }
};

const validationsByType = {
  INTEGER(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;
    isValid = isValid && !Number.isNaN(window.parseInt(value));
    return { isValid, messages };
  },
  FLOAT(n) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = Number(n).toString() === n.toString();
    return { isValid, messages };
  },
  NUMBER(n) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = Number(n).toString() === n.toString();
    return { isValid, messages };
  },
  LINK(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;
    return { isValid, messages };
  },
  PHONENUMBER(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;
    return { isValid, messages };
  },
  STRING(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;

    return { isValid, messages };
  },
  EMAIL(value) {
    let isValid = true;
    const messages = [utils.getLang('smartmessaging.fieldValidity.mandatory')];
    isValid = !!value;
    if (isValid) {
      const re = /^\S+@(?!.*?\.\.)[A-Za-z0-9.-]+\.+[A-Za-z0-9]{2,}$/;

      const isMail = re.test(value);

      if (!isMail) messages.push(utils.getLang('smartmessaging.fieldValidity.incorrectEmail'));
      isValid = isValid && isMail;
    }
    return { isValid, messages };
  },
};

const getValidity = (type, v) => validationsByType[type](v);

const getSenderValidity = (k, v, atId) => {
  if (k === 'clubId') return validationsByType.INTEGER(v);
  if (k === 'recipient')
    return atId === 1 ? validationsByType.PHONENUMBER(v) : validationsByType.EMAIL(v);
  return { isValid: false, messages: ['Sender Validation issue'] };
};

function WithTestFormController({
  UI,
  data,
  includeWizVille,
  campaignActionId,
  actionTypeId,
  callApi,
  currentAppLn,
  ...others
}) {
  const [values, setValues] = useState(
    Object.keys(data.fieldsInfos)
      .filter(f => {
        if (f === 'wizvilleBlock') return false;
        return !!includeWizVille || f !== 'wizvilleentryLink';
      })
      .reduce(
        (m, f) => ({
          ...m,
          [f]: {
            value: getDefaultValue(f, data),
            validity: getValidity(data.fieldsInfos[f], getDefaultValue(f, data)),
          },
        }),
        {}
      )
  );

  const [senderValues, updateSender] = useState({
    clubId: { value: null, validity: getSenderValidity('clubId', null) },
    recipient: { value: null, validity: getSenderValidity('recipient', null, actionTypeId) },
  });

  const notifyChange = useCallback(
    (k, v) => {
      if (Object.keys(senderValues).indexOf(k) !== -1) {
        updateSender(prev => ({
          ...prev,
          [k]: { value: v, validity: getSenderValidity(k, v, actionTypeId) },
        }));
      } else
        setValues(prev => ({
          ...prev,
          [k]: { value: v, validity: getValidity(data.fieldsInfos[k], v) },
        }));
    },
    [actionTypeId, data.fieldsInfos, senderValues]
  );

  const submit = useCallback(
    async callback => {
      await callApi(apiClient.testCampaignAction, [
        {
          ...Object.entries(senderValues).reduce((m, [k, v]) => ({ ...m, [k]: v.value }), {}),
          campaignActionId,
          mappedModel: Object.entries(values).reduce((m, [k, v]) => ({ ...m, [k]: v.value }), {}),
        },
      ]);
      if (callback) callback();
    },
    [callApi, campaignActionId, senderValues, values]
  );

  const getOptions = useCallback(
    key => {
      switch (key) {
        case FieldKey.CIVILITY:
          return data.civilities.map(c => ({
            label: utils.getCivilityLabel(c, currentAppLn.locale),
            value: c.key,
          }));
        case 'clubId':
          return data.clubs.map(c => ({
            label: c.name,
            value: c.id,
          }));
        default:
          return null;
      }
    },
    [data, currentAppLn]
  );

  const formIsValid = useMemo(
    () =>
      !Object.values(senderValues).find(f => !f.validity.isValid) &&
      !Object.values(values).find(f => !f.validity.isValid),
    [values, senderValues]
  );

  return (
    <UI
      values={values}
      senderValues={senderValues}
      notifyChange={notifyChange}
      submit={submit}
      formIsValid={formIsValid}
      fields={data.fieldsInfos}
      clubs={data.clubs}
      civilities={data.civilities}
      actionTypeId={actionTypeId}
      getOptions={getOptions}
      {...others}
    />
  );
}

const WithData = withDataResolver({
  resolve: props => async callApi => {
    const { rmId, actionTypeId, forcedFields } = props;

    let fieldsInfos;
    if (!forcedFields)
      fieldsInfos = await callApi(apiClient.getContentFieldsForRequestModel, [rmId, actionTypeId]);
    else fieldsInfos = forcedFields;

    const clubs = await callApi(apiClient.getClubs);

    const cleanFields = Object.entries(fieldsInfos).reduce(
      (map, [k, v]) =>
        k !== 'invitationLink' && ['WIZVILLEBLOCK', 'IMAGE', 'TABLE'].indexOf(v) === -1
          ? { ...map, [k]: v }
          : map,
      {}
    );

    // load civilities : available civilities for club
    // TODO : facto/externaliser en loadCivilities
    // TODO : move 'club.addressCountryISO' to an enum
    const clubCountryISO = sessionStorage.getItem('club.addressCountryISO');
    const doFilterCivilities = c => {
      if (!c.countryIsos) return true;
      if (c.countryIsos.indexOf(clubCountryISO) !== -1) return true;
      return false;
    };

    const rawCivilities = await callApi(apiClient.getDataList, [
      { beantypeId: EBeantype.CIVILITY.id },
    ]);

    return {
      civilities: rawCivilities.filter(doFilterCivilities),
      clubs,
      fieldsInfos: cleanFields,
    };
  },
  onResolved: () => async () => {},
})(withApiCaller(WithTestFormController));

const WithAlert = withAlertAndLoader(WithData);

export default UI => props => <WithAlert {...props} UI={UI} />;
