import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Fade, Button } from '@mui/material';
import MassActionMailEditor from './MassActionMailEditor';
import MassActionSMSEditor from './MassActionSMSEditor';
import DiffusionTypeButton from './DiffusionTypeButton';
import MessageActionAppBar from './MessageActionAppBar';
import MassActionNotifyEditor from './MassActionNotifyEditor';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';
import { handleContentChange, resetContentEditorFlowData } from 'src/reducers/massAction';
import utils from 'src/utils/utils';
import paramSelectors from 'src/selectors/parameters';
import SmsContentLabel from './SmsContentLabel';
import ActionType from 'src/enums/actionType';
import { CONTENT_SELECTOR } from '../MassActionContent';

function PageContent({
  actionType,
  fields,
  content,
  object,
  params,
  setStep,
  goToStep,
  doHandleContentChange,
  onObjectEditorLoaded,
  onEditorLoaded,
  actionId,
  isWritable,
  clearCurrentCmpAction,
}) {
  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'left',
      }}
    >
      <MessageActionAppBar
        setStep={setStep}
        goToStep={goToStep}
        actionType={actionType}
        fields={fields}
        actionId={actionId}
        isWritable={isWritable}
      />
      <Fade in timeout={500}>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            padding: '8px',
          }}
        >
          {[ActionType.MEMBER_NOTIFICATION.id].indexOf(actionType) === -1 && (
            <Typography
              color="textSecondary"
              style={{ margin: '5px 10px', display: 'inline' }}
              variant="subtitle2"
              noWrap
              component="div"
            >
              {utils.getLang('smartmessaging.massAction.diffusionTypeLabel')}
            </Typography>
          )}
          {[ActionType.MEMBER_NOTIFICATION.id].indexOf(actionType) === -1 && (
            <div style={{ display: 'inline' }}>
              <DiffusionTypeButton actionType={actionType} />
            </div>
          )}
          {actionType === ActionType.SMS.id && (
            <div style={{ width: '320px' }}>
              <div key={0}>
                <SmsContentLabel />
              </div>
              <MassActionSMSEditor
                key={1}
                isWritable={isWritable}
                fields={fields}
                content={contentEditorUtils.smsFreemarkerToHtml(
                  content,
                  fields.fieldsModels,
                  params
                )}
                onChange={doHandleContentChange}
                onEditorLoaded={onEditorLoaded}
              />
            </div>
          )}
          {actionType === ActionType.EMAIL.id && (
            <MassActionMailEditor
              onObjectEditorLoaded={onObjectEditorLoaded}
              isWritable={isWritable}
              onEditorLoaded={onEditorLoaded}
              onChange={doHandleContentChange}
              fields={fields}
              content={contentEditorUtils.freemarkerToHtml(content, fields.fieldsModels, params)}
              object={contentEditorUtils.freemarkerToHtml(object, fields.fieldsModels, params)}
            />
          )}
          {actionType === ActionType.MEMBER_NOTIFICATION.id && (
            <MassActionNotifyEditor
              onObjectEditorLoaded={onObjectEditorLoaded}
              isWritable={isWritable}
              onEditorLoaded={onEditorLoaded}
              onChange={doHandleContentChange}
              fields={fields}
              content={contentEditorUtils.freemarkerToHtml(content, fields.fieldsModels, params)}
              object={contentEditorUtils.freemarkerToHtml(object, fields.fieldsModels, params)}
            />
          )}
        </div>
      </Fade>
      <div style={{ textAlign: 'right', padding: '8px' }}>
        <Button
          variant="text"
          color="primary"
          style={{ margin: '2px' }}
          onClick={e => {
            e.preventDefault();
            clearCurrentCmpAction();
            goToStep(CONTENT_SELECTOR);
          }}
        >
          {utils.getLang('smartmessaging.buttonLabel.previous')}
        </Button>
      </div>
    </div>
  );
}

PageContent.propTypes = {
  actionType: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  object: PropTypes.string,
  fields: PropTypes.object,
  params: PropTypes.object.isRequired,
  goToStep: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  doHandleContentChange: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func.isRequired,
  onEditorLoaded: PropTypes.func.isRequired,
  actionId: PropTypes.number,
  isWritable: PropTypes.bool.isRequired,
  clearCurrentCmpAction: PropTypes.func.isRequired,
};

PageContent.defaultProps = { object: '', actionId: null, fields: null };

const MessageContentEditor = ({
  actionType,
  fields,
  content,
  object,
  clubParams,
  goToStep,
  onObjectEditorLoaded,
  onEditorLoaded,
  doHandleContentChange,
  setStep,
  actionId,
  isWritable,
  clearCurrentCmpAction,
}) => (
  <PageContent
    isWritable={isWritable}
    goToStep={goToStep}
    setStep={setStep}
    fields={contentEditorUtils.processContentFields(fields, actionType, {
      headerFileId: clubParams.headerStoredfileId || null,
      footerFileId: clubParams.footerStoredfileId || null,
    })}
    params={{
      headerFileId: clubParams.headerStoredfileId || null,
      footerFileId: clubParams.footerStoredfileId || null,
    }}
    doHandleContentChange={() => doHandleContentChange(actionType)}
    onObjectEditorLoaded={onObjectEditorLoaded}
    onEditorLoaded={onEditorLoaded}
    content={content}
    object={object}
    actionType={actionType}
    actionId={actionId}
    clearCurrentCmpAction={clearCurrentCmpAction}
  />
);

MessageContentEditor.propTypes = {
  actionType: PropTypes.number.isRequired,
  actionId: PropTypes.number,
  content: PropTypes.string.isRequired,
  object: PropTypes.string,
  fields: PropTypes.object,
  clubParams: PropTypes.object.isRequired,
  goToStep: PropTypes.func.isRequired,
  doHandleContentChange: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func.isRequired,
  onEditorLoaded: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
  clearCurrentCmpAction: PropTypes.func.isRequired,
};

MessageContentEditor.defaultProps = {
  object: '',
  actionId: null,
  fields: null,
};

const mapStateToProps = state => ({
  clubParams: paramSelectors.getCurrentParams(state),
});

export default connect(mapStateToProps, {
  doHandleContentChange: handleContentChange,
  doResetContentEditorFlowData: resetContentEditorFlowData,
  clearCurrentCmpAction: () => ({ type: 'UPDATE_CURRENT_CMP_ACTION', value: null }),
  onObjectEditorLoaded: objectEditor => ({
    type: 'ON_MASS_ACTION_MAIL_OBJECT_EDITOR_LOADED',
    value: objectEditor,
  }),
  onEditorLoaded: editor => ({
    type: 'ON_MASS_ACTION_MAIL_CONTENT_EDITOR_LOADED',
    value: editor,
  }),
})(MessageContentEditor);
