import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';
import { Typography } from '@mui/material';
import utils from 'src/utils/utils';
import CKEditor, { defaultCkeHtml } from '../ReactCKEditor';
import { useTheme } from '@mui/material/styles';

const EmailEditorRender = ({
  fields,
  content,
  object,
  nodeRef,
  rendered,
  onEditorLoaded,
  onObjectEditorLoaded,
  onChange,
  readOnly,
}) => {
  const theme = useTheme();
  return (
    <div style={{ height: '85%' }} ref={nodeRef}>
      {rendered && (
        <Typography
          style={{ margin: '5px 10px', color: theme.palette.text.secondary }}
          variant="subtitle2"
          noWrap
        >
          {utils.getLang('smartmessaging.contentEditor.mail.object')}
        </Typography>
      )}
      {rendered && (
        <CKEditor
          name="objectEditor"
          config={{
            readOnly: !!readOnly,
            fields: fields.fields.objectFields,
            removeButtons:
              'Underline,Subscript,Superscript,' +
              'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
              'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
              'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
              'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
              'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
              'BidiLtr,BidiRtl,CreatePlaceholder,' +
              'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
            extraPlugins: 'strinsert',
            removePlugins: 'colorbutton,elementspath',
            autoParagraph: false,
            enterMode: 2,
            height: 10 * (nodeRef.current.clientHeight / 100),
          }}
          events={{
            change: onChange,
            loaded: event => {
              onObjectEditorLoaded(event.editor);
            },
          }}
          content={object}
        />
      )}
      {rendered && (
        <Typography
          variant="subtitle2"
          style={{ paddingTop: '16px', margin: '5px 10px', color: theme.palette.text.secondary }}
          noWrap
        >
          {utils.getLang('smartmessaging.contentEditor.mail.content')}
        </Typography>
      )}
      {rendered && (
        <CKEditor
          name="htmlEditor"
          config={{
            readOnly: !!readOnly,
            fullPage: true,
            allowedContent: true,
            fields: fields.fields.contentFields,
            removeButtons:
              'Underline,Subscript,Superscript,Anchor,About,Copy,Paste,PasteText,PasteFromWord,Cut,Scayt',
            disallowedContent: 'img{border*,margin*,float*,width,height}; table{width,height}',
            extraAllowedContent: 'script; img[width,height]; table[width,height]',
            extraPlugins: 'strinsert,uploadimage',
            imageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/storedfile/fileUpload/`,
            filebrowserImageUploadUrl: `${process.env.REACT_APP_API_BASE_URL}/storedfile/fileUpload/`,
            removePlugins: 'elementspath',
            autoParagraph: false,
            enterMode: 2,
            height: 70 * (nodeRef.current.clientHeight / 100),
          }}
          events={{
            loaded: event => {
              onEditorLoaded(event.editor);
            },
            change: onChange,
            instanceReady: event => {
              if (content) event.editor.setData(content);
              else event.editor.setData(defaultCkeHtml);
            },
            fileUploadRequest: event => {
              const { xhr, file, fileName } = event.data.fileLoader;
              const smStore = JSON.parse(sessionStorage.getItem('sm-store'));
              xhr.setRequestHeader('Authorization', `Bearer ${smStore.security.access_token}`);
              xhr.setRequestHeader(
                'X-User-Club-Id',
                `/${smStore.client}/clubs/${sessionStorage.getItem('club')}`
              );

              const networknode = sessionStorage.getItem('networknode');
              if (networknode)
                xhr.setRequestHeader(
                  'X-User-Network-Node-Id',
                  `/${smStore.client}/network_nodes/${networknode}`
                );
              const formData = new FormData();
              formData.append('file', file, fileName);
              xhr.send(formData);
              event.stop();
            },
            fileUploadResponse: event => {
              event.stop();
              const { data } = event;
              const { responseText } = event.data.fileLoader.xhr;
              data.url = `${process.env.REACT_APP_API_BASE_URL}/media/${responseText}`;
            },
          }}
        />
      )}
    </div>
  );
};

EmailEditorRender.propTypes = {
  fields: PropTypes.any.isRequired,
  nodeRef: PropTypes.object.isRequired,
  rendered: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  object: PropTypes.string,
  onEditorLoaded: PropTypes.func.isRequired,
  onObjectEditorLoaded: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

EmailEditorRender.defaultProps = {
  object: '',
  readOnly: false,
};
/** Used to render ckeditors after containers are rendered for ckeditors height
 *  cause it's value is not state bound (to avoid rerenders causing ckeditors layout issues)
 */
class EmailEditorWithRef extends Component {
  constructor(props) {
    super(props);
    this.myNodeRef = React.createRef();
    this.onCKEditorDialogDefintion = this.onCKEditorDialogDefintion.bind(this);
  }

  componentDidMount() {
    this.onCKEditorDialogDefintion();
    this.props.setRendered(true);
  }

  // eslint-disable-next-line class-methods-use-this
  onCKEditorDialogDefintion() {
    window.CKEDITOR.on('dialogDefinition', ev => {
      // Take the dialog name and its definition from the event
      const dialogName = ev.data.name;
      const dialogDefinition = ev.data.definition;

      // vérifie qu'on est sur la popup des liens

      if (dialogName === 'link') {
        const infoTab = dialogDefinition.getContents('info');
        infoTab.remove('emailBody');
        infoTab.remove('emailSubject');
      }
      if (dialogName === 'image') {
        const linkInfoTab = dialogDefinition.getContents('Link');
        linkInfoTab.remove('cmbTarget');
        linkInfoTab.remove('browse');
        const txtUrl = linkInfoTab.get('txtUrl');
        // txtUrl.default = 'https://';
        txtUrl.onChange = function onChange() {
          let c = this.getValue();
          if (c && !/^http:\/\/|https:\/\//g.test(c)) {
            c = `http://${c}`;
            this.setValue(c);
          }
        };
      }
    });
  }

  render() {
    return <EmailEditorRender {...this.props} nodeRef={this.myNodeRef} />;
  }
}

EmailEditorWithRef.propTypes = {
  setRendered: PropTypes.func.isRequired,
  rendered: PropTypes.bool.isRequired,
  fields: PropTypes.object,
};

EmailEditorWithRef.defaultProps = {
  fields: { fields: {} },
};

export default withState('rendered', 'setRendered', () => false)(EmailEditorWithRef);
