import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withState } from 'recompose';
import SmsValidity from './SmsValidity';

import CKEditor from 'src/components/common/ReactCKEditor';

const MassActionSMSEditorRender = ({
  fields,
  content,
  nodeRef,
  rendered,
  onEditorLoaded,
  onChange,
  isWritable,
}) => (
  <div
    style={{
      height: '85%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      flex: 1,
      overflow: 'visible',
    }}
    ref={nodeRef}
  >
    {rendered && (
      <div>
        <CKEditor
          name="objectEditor"
          config={{
            readOnly: !isWritable,
            fields: fields.fields,
            allowedContent: true,
            removeButtons:
              'Underline,Subscript,Superscript,' +
              'Source,Anchor,Maximize,About,Copy,Paste,PasteText,PasteFromWord,' +
              'Cut,Scayt,Undo,Redo,Form,Checkbox,Radio,TextField,Textarea,Select,Button,' +
              'ImageButton,HiddenField,Bold,Italic,Underline,Strike,Subscript,Superscript,' +
              'RemoveFormat,NumberedList,BulletedList,Outdent,Indent,Blockquote,CreateDiv,' +
              'JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,' +
              'BidiLtr,BidiRtl,CreatePlaceholder,' +
              'Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,InsertPre,Styles,Format,Font,FontSize,Unlink,Link',
            extraPlugins: 'strinsert',
            removePlugins: 'colorbutton,elementspath',
            autoParagraph: false,
            enterMode: 2,
          }}
          events={{
            loaded: e => {
              onEditorLoaded(e.editor);
            },
            change: onChange,
            instanceReady: event => {
              if (content) event.editor.setData(content);
            },
          }}
        />
        <SmsValidity key={3} />
      </div>
    )}
  </div>
);

MassActionSMSEditorRender.propTypes = {
  fields: PropTypes.any.isRequired,
  nodeRef: PropTypes.object.isRequired,
  rendered: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  onEditorLoaded: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isWritable: PropTypes.bool.isRequired,
};

/** Used to render ckeditors after containers are rendered for ckeditors height
 *  cause it's value is not state bound (to avoid rerenders causing ckeditors layout issues)
 */
class MassActionSMSEditorWithRef extends Component {
  constructor(props) {
    super(props);
    this.myNodeRef = React.createRef();
  }

  componentDidMount() {
    this.props.setRendered(true);
  }

  render() {
    return <MassActionSMSEditorRender {...this.props} nodeRef={this.myNodeRef} />;
  }
}

MassActionSMSEditorWithRef.propTypes = {
  setRendered: PropTypes.func.isRequired,
  rendered: PropTypes.bool.isRequired,
  fields: PropTypes.object,
};

MassActionSMSEditorWithRef.defaultProps = {
  fields: { fields: {} },
};

export default withState('rendered', 'setRendered', () => false)(MassActionSMSEditorWithRef);
